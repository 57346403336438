import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import NavBar from './NavBar';
import './SurfBreaks.css';

const initialSurfBreaks = [
  { name: 'Amir Luria', surfBreakName: 'Tourmaline', lat: 32.8051, lon: -117.2622, rating: 8.0 },
  { name: 'Amir Luria', surfBreakName: 'San Elijio (Tower 17)', lat: 33.0247649, lon: -117.2864262, rating: 7.0 },
  { name: 'Amir Luria', surfBreakName: 'San Onofre State Park', lat: 33.3728, lon: -117.5656, rating: 9.0 },
  { name: 'Amir Luria', surfBreakName: 'Scripps', lat: 32.8663, lon: -117.2546, rating: 7.0 },
  { name: 'Amir Luria', surfBreakName: 'Coronado', lat: 32.6848, lon: -117.1878, rating: 5.0 },
  { name: 'Amir Luria', surfBreakName: 'Carp', lat: 34.3925, lon: -119.5213, rating: 7.0 },
  { name: 'Amir Luria', surfBreakName: 'Linda Mar', lat: 37.5989, lon: -122.5019, rating: 8.0 },
  { name: 'Amir Luria', surfBreakName: 'Leadbetter SB', lat: 34.4024, lon: -119.6993, rating: 7.0 },
  { name: 'Amir Luria', surfBreakName: 'Pleasure Point', lat: 36.9635, lon: -121.9649, rating: 9.0 },
  { name: 'Amir Luria', surfBreakName: 'Hanalei Bay', lat: 22.2091, lon: -159.5068, rating: 9.0 },
  { name: 'Amir Luria', surfBreakName: 'Anahola Bay', lat: 22.1508, lon: -159.3012, rating: 8.0 },
  { name: 'Amir Luria', surfBreakName: 'Gils Ashdod', lat: 31.7921, lon: 34.6274, rating: 8.0 },
];

const mapStyles = {
  Outdoors: 'mapbox/outdoors-v11',
  Light: 'mapbox/light-v10',
  Dark: 'mapbox/dark-v10',
  Satellite: 'mapbox/satellite-v9',
  SatelliteStreets: 'mapbox/satellite-streets-v11',
  NavigationDay: 'mapbox/navigation-day-v1',
  NavigationNight: 'mapbox/navigation-night-v1',
};

function SurfBreaks() {
  const [surfBreaks, setSurfBreaks] = useState(initialSurfBreaks);
  const [form, setForm] = useState({
    name: '',
    surfBreakName: '',
    lat: '',
    lon: '',
    rating: ''
  });
  const [selectedStyle, setSelectedStyle] = useState(mapStyles.Light);
  const [selectedBreak, setSelectedBreak] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSurfBreaks([...surfBreaks, form]);
    setForm({ name: '', surfBreakName: '', lat: '', lon: '', rating: '' });
  };

  const handleRowClick = (breakPoint) => {
    setSelectedBreak(breakPoint);
  };

  const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYW1pcmx1cmlhIiwiYSI6ImNsemJxZXd6czAzamoybXEwdW1mMWttaHQifQ.OjsYInG9IavEklyY4HTOnw';

  return (
    <div className="surf-breaks">
      <NavBar />
      <hr className="divider" />
      <div className="surf-breaks-content">
        <h1 className="surf-breaks-header">Surf Breaks</h1>
        <p className="surf-breaks-paragraph">Some of my favorite surf spots from around the world. You can explore and add your own breaks below</p>
      </div>
      <MapContainer
        center={[31.970880, -42.103319]} // Center the map to show the United States, including California and Hawaii
        zoom={2.5} // Zoom level to fit the area
        style={{ height: '500px', width: '100%' }}
      >
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/${selectedStyle}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
          tileSize={512}
          zoomOffset={-1}
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {surfBreaks.map((breakPoint, index) => (
          <Marker key={index} position={[breakPoint.lat, breakPoint.lon]}>
            <Popup>
              <strong>Surf Break:</strong> {breakPoint.surfBreakName}<br />
              <strong>User:</strong> {breakPoint.name}<br />
              <strong>Rating:</strong> {breakPoint.rating}/10
            </Popup>
          </Marker>
        ))}
        {selectedBreak && (
          <FlyToLocation lat={selectedBreak.lat} lon={selectedBreak.lon} />
        )}
      </MapContainer>
      <div className="map-style-selector-container">
        <div className="map-style-selector">
          <label htmlFor="mapStyle">Choose Map Style: </label>
          <select id="mapStyle" onChange={(e) => setSelectedStyle(e.target.value)}>
            {Object.entries(mapStyles).map(([styleName, styleId]) => (
              <option key={styleId} value={styleId}>{styleName}</option>
            ))}
          </select>
        </div>
      </div>
      <hr className="divider" />
      <form onSubmit={handleSubmit} className="surf-breaks-form">
        <input
          type="text"
          name="name"
          value={form.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
        />
        <input
          type="text"
          name="surfBreakName"
          value={form.surfBreakName}
          onChange={handleChange}
          placeholder="Surf Break Name"
          required
        />
        <input
          type="number"
          step="0.0001"
          name="lat"
          value={form.lat}
          onChange={handleChange}
          placeholder="Latitude"
          required
        />
        <input
          type="number"
          step="0.0001"
          name="lon"
          value={form.lon}
          onChange={handleChange}
          placeholder="Longitude"
          required
        />
        <input
          type="number"
          step="0.1"
          name="rating"
          value={form.rating}
          onChange={handleChange}
          placeholder="Rating (out of 10)"
          required
        />
        <button type="submit">Add</button>
      </form>
      <table className="surf-breaks-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Surf Break</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Rating</th>
          </tr>
        </thead>
        <tbody>
          {surfBreaks.map((breakPoint, index) => (
            <tr key={index} onClick={() => handleRowClick(breakPoint)}>
              <td>{breakPoint.name}</td>
              <td>{breakPoint.surfBreakName}</td>
              <td>{breakPoint.lat}</td>
              <td>{breakPoint.lon}</td>
              <td>{breakPoint.rating}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function FlyToLocation({ lat, lon }) {
  const map = useMap();
  map.flyTo([lat, lon], 12); // Adjust the zoom level as needed
  return null;
}

export default SurfBreaks;
