import React, { useEffect, useState } from 'react';
import { IoTriangle } from "react-icons/io5";
import * as XLSX from 'xlsx';
import NavBar from './NavBar';
import './Deals.css';

// Importing all the company logos
import AirtableLogo from './images/Airtable.png';
import IntercomLogo from './images/Intercom.png';
import HomelightLogo from './images/Homelight.png';
import FloQastLogo from './images/FloQast.png';
import FlexportLogo from './images/Flexport.webp';
import EpicGamesLogo from './images/Epic Games.png';
import DatarobotLogo from './images/Datarobot.jpg';
import ChainalysisLogo from './images/Chainalysis.webp';
import CerebrasLogo from './images/Cerebras.jpg';
import AlloyLogo from './images/Alloy.jpeg';
import InvocaLogo from './images/Invoca.png';
import VerbitLogo from './images/Verbit.png';
import TradeshiftLogo from './images/Tradeshift.png';
import ThoughtspotLogo from './images/Thoughtspot.png';
import RippleLogo from './images/Ripple.svg';
import ReltioLogo from './images/Reltio.jpeg';
import RelativitySpaceLogo from './images/Relativity Space.jpg';
import PlacerLogo from './images/Placer.jpg';
import OpenAILogo from './images/OpenAI.png';
import KrakenLogo from './images/Kraken.jpg';
import ZiplineLogo from './images/Zipline.jpg';
import ScaleAILogo from './images/ScaleAI.jpg';
import AnthropicLogo from './images/Anthropic.png';  
import SambanovaLogo from './images/Sambanova.png';  
import NotionLogo from './images/Notion.png';  

const logos = {
  Airtable: AirtableLogo,
  Intercom: IntercomLogo,
  HomeLight: HomelightLogo,
  FloQast: FloQastLogo,
  Flexport: FlexportLogo,
  'Epic Games': EpicGamesLogo,
  DataRobot: DatarobotLogo,
  Chainalysis: ChainalysisLogo,
  Cerebras: CerebrasLogo,
  Alloy: AlloyLogo,
  Invoca: InvocaLogo,
  Verbit: VerbitLogo,
  Tradeshift: TradeshiftLogo,
  ThoughtSpot: ThoughtspotLogo,
  Ripple: RippleLogo,
  Reltio: ReltioLogo,
  'Relativity Space': RelativitySpaceLogo,
  Placer: PlacerLogo,
  OpenAI: OpenAILogo,
  Kraken: KrakenLogo,
  Zipline: ZiplineLogo,
  ScaleAI: ScaleAILogo,
  Anthropic: AnthropicLogo,
  SambaNova: SambanovaLogo,
  Notion: NotionLogo,
};

function Deals() {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    fetch('/dealtable.xlsx')
      .then(response => response.arrayBuffer())
      .then(data => {
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setDeals(jsonData);
      })
      .catch(error => console.error('Error fetching the deals data:', error));
  }, []);

  return (
    <div className="deals-page">
      <NavBar />
      <hr className="divider" />
      <div className="deals-content">
        <h1>Closed Deals</h1>
        <p>Below are some transactions I've closed over the past three years. Generally, I focus on B2B Saas, AI, and FinTech startups</p>
        <div className="deals-table-container">
          <table className="deals-table">
            <tbody>
              {deals.map((deal, index) => (
                <tr key={index}>
                  <td className="company">
                    <IoTriangle color='black' />
                    {logos[deal['Company']] && <img src={logos[deal['Company']]} alt={deal['Company']} className="company-logo" />}
                    <a href={deal['Link']} target="_blank" rel="noopener noreferrer">
                      {deal['Company']}
                    </a>
                  </td>
                  <td>{deal['Segment']}</td>
                  <td className="description">{deal['Description']}</td>
                  <td>{deal['Deal Size']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <hr className="bottom-divider" />
      <p className="footer">For additional questions email <a href="mailto:bmaluria@gmail.com" className="blue-link">bmaluria@gmail.com</a></p>
      <p className="footer">Or, go back to the <a href="/" className="blue-link">Home Page</a></p>
    </div>
  );
}

export default Deals;
