import React from 'react';
import NavBar from './NavBar'; // Make sure NavBar is included
import './Books.css';

function Books() {
  const lastUpdated = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="books-page">
      <NavBar />
      <hr className="divider" />
      <p className="intro-text">Cool stuff I've been reading</p>
      <div className="books-content">
        <h1>Articles</h1>
        <ul className="articles-list">
          <li className="article-item">
            <a href="https://eastwind.substack.com/p/a-deep-dive-on-ai-inference-startups" target="_blank" rel="noopener noreferrer" className="article-link">Deep Dive on AI Inference Startups</a>
          </li>
          <li className="article-item">
            <a href="https://medium.com/@m.weichel/visualizing-secondaries-the-basics-9b67fd7e13d7" target="_blank" rel="noopener noreferrer" className="article-link">Visualizing Secondaries: The Basics</a>
          </li>
          <li className="article-item">
            <a href="https://www.datacenterdynamics.com/en/marketwatch/2024-the-year-of-liquid-cooling/" target="_blank" rel="noopener noreferrer" className="article-link">2024: The year of liquid cooling?</a>
          </li>
          <li className="article-item">
            <a href="https://eastwind.substack.com/p/zombiecalypse-in-startupland" target="_blank" rel="noopener noreferrer" className="article-link">Zombiecalypse in Startupland</a>
          </li>
          <li className="article-item">
            <a href="https://docsend.com/view/5hk8prddivq54nne" target="_blank" rel="noopener noreferrer" className="article-link">Avenir x SaaS: What's Gone Wrong in Software and Why We're Optimistic</a>
          </li>
          <li className="article-item">
            <a href="https://arxiv.org/pdf/2305.17493" target="_blank" rel="noopener noreferrer" className="article-link">The Curse Of Recursion: Training On Generated Data Makes Models Forget</a>
          </li>
        </ul>
        <h1>Books</h1>
        <ul className="books-list">
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/34452.Many_Lives_Many_Masters?ref=nav_sb_ss_1_24" target="_blank" rel="noopener noreferrer" className="book-link">Many Lives, Many Masters</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/333538.The_Castle?ref=nav_sb_ss_5_10" target="_blank" rel="noopener noreferrer" className="book-link">The Castle</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/629.Zen_and_the_Art_of_Motorcycle_Maintenance?ref=nav_sb_ss_1_12" target="_blank" rel="noopener noreferrer" className="book-link">Zen and the Art of Motorcycle Maintenance</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/22571519-the-italians?ref=nav_sb_ss_1_19" target="_blank" rel="noopener noreferrer" className="book-link">The Italians</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/396931.The_Origins_of_Totalitarianism" target="_blank" rel="noopener noreferrer" className="book-link">The Origins of Totalitarianism</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/397483.The_Devil_in_the_White_City?ref=nav_sb_ss_1_12" target="_blank" rel="noopener noreferrer" className="book-link">The Devil In The White City</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/9477628-jerusalem?ref=nav_sb_ss_3_10" target="_blank" rel="noopener noreferrer" className="book-link">Jerusalem: Simon Sebag Montefiore</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/59064518-the-metaverse?ref=nav_sb_ss_1_13" target="_blank" rel="noopener noreferrer" className="book-link">The Metaverse</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/54898389-the-almanack-of-naval-ravikant?ref=nav_sb_ss_1_21" target="_blank" rel="noopener noreferrer" className="book-link">The Almanack Of Naval Ravikant</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/5129.Brave_New_World?ref=nav_sb_ss_1_15" target="_blank" rel="noopener noreferrer" className="book-link">Brave New World</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/612188.Memories_Dreams_Reflections?ref=nav_sb_ss_1_15" target="_blank" rel="noopener noreferrer" className="book-link">Memories, Dreams, Reflections</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/4599.Carter_Beats_the_Devil" target="_blank" rel="noopener noreferrer" className="book-link">Carter Beats the Devil</a>
          </li>
          <li className="book-item">
            <a href="https://www.goodreads.com/book/show/421757.Disraeli" target="_blank" rel="noopener noreferrer" className="book-link">Disraeli: A Biography</a>
          </li>
           <li className="book-item">
            <a href="https://www.goodreads.com/book/show/355789.The_Garden_of_the_Finzi_Continis" target="_blank" rel="noopener noreferrer" className="book-link">The Garden of the Finzi-Continis</a>
          </li>
        </ul>
      </div>
      <hr className="bottom-divider" />
      <div className="footer">
        <p>Last updated: {lastUpdated}</p>
      </div>
    </div>
  );
}

export default Books;
