import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import SurfBreaks from './components/SurfBreaks';
import Deals from './components/Deals';
import Books from './components/Books';
import './App.css';
import funnyMeme from './components/images/funny_meme_1.png';

function App() {
  const [showMeme, setShowMeme] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.metaKey && e.key === 'a') {
        e.preventDefault();
        setShowMeme(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {showMeme && (
        <div className="meme-overlay">
          <div className="meme-container">
            <button className="close-button" onClick={() => setShowMeme(false)}>
              &times;
            </button>
            <img src={funnyMeme} alt="Funny Meme" className="meme-image" />
          </div>
        </div>
      )}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/surf-breaks" element={<SurfBreaks />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/books" element={<Books />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
