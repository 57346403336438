// src/components/NavBar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChessBoard } from "react-icons/fa";
import './NavBar.css';

function NavBar() {
  const location = useLocation();

  return (
    <div className="nav-bar">
      <Link to="/">
        <FaChessBoard className="nav-icon" />
      </Link>
      <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
      <Link to="/surf-breaks" className={location.pathname === '/surf-breaks' ? 'active' : ''}>Surf Breaks</Link>
      <Link to="/deals" className={location.pathname === '/deals' ? 'active' : ''}>Deals</Link>
      <Link to="/books" className={location.pathname === '/books' ? 'active' : ''}>Books</Link>
    </div>
  );
}

export default NavBar;
