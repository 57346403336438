import React, { useEffect, useState } from 'react';
import { IoTriangle } from "react-icons/io5";
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import './Home.css';
import amirHeadshot from './amir_headshot.jpeg';
import NavBar from './NavBar'; // Importing the NavBar component

// Importing all the company logos
import AirtableLogo from './images/Airtable.png';
import IntercomLogo from './images/Intercom.png';
import HomelightLogo from './images/Homelight.png';
import FloQastLogo from './images/FloQast.png';
import FlexportLogo from './images/Flexport.webp';
import EpicGamesLogo from './images/Epic Games.png';
import DatarobotLogo from './images/Datarobot.jpg';
import ChainalysisLogo from './images/Chainalysis.webp';
import CerebrasLogo from './images/Cerebras.jpg';
import AlloyLogo from './images/Alloy.jpeg';
import InvocaLogo from './images/Invoca.png';
import VerbitLogo from './images/Verbit.png';
import TradeshiftLogo from './images/Tradeshift.png';
import ThoughtspotLogo from './images/Thoughtspot.png';
import RippleLogo from './images/Ripple.svg';
import ReltioLogo from './images/Reltio.jpeg';
import RelativitySpaceLogo from './images/Relativity Space.jpg';
import PlacerLogo from './images/Placer.jpg';
import OpenAILogo from './images/OpenAI.png';
import KrakenLogo from './images/Kraken.jpg';
import ZiplineLogo from './images/Zipline.jpg';
import ScaleAILogo from './images/ScaleAI.jpg';
import AnthropicLogo from './images/Anthropic.png';  // Changed to .png
import SambanovaLogo from './images/Sambanova.png';  // Changed to .png and corrected capitalization
import NotionLogo from './images/Notion.png';  // Changed to .png

const logos = {
  Airtable: AirtableLogo,
  Intercom: IntercomLogo,
  HomeLight: HomelightLogo,
  FloQast: FloQastLogo,
  Flexport: FlexportLogo,
  'Epic Games': EpicGamesLogo,
  DataRobot: DatarobotLogo,
  Chainalysis: ChainalysisLogo,
  Cerebras: CerebrasLogo,
  Alloy: AlloyLogo,
  Invoca: InvocaLogo,
  Verbit: VerbitLogo,
  Tradeshift: TradeshiftLogo,
  ThoughtSpot: ThoughtspotLogo,
  Ripple: RippleLogo,
  Reltio: ReltioLogo,
  'Relativity Space': RelativitySpaceLogo,
  Placer: PlacerLogo,
  OpenAI: OpenAILogo,
  Kraken: KrakenLogo,
  Zipline: ZiplineLogo,
  ScaleAI: ScaleAILogo,
  Anthropic: AnthropicLogo,
  SambaNova: SambanovaLogo,
  Notion: NotionLogo,
};

function Home() {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    fetch('/dealtable.xlsx')
      .then(response => response.arrayBuffer())
      .then(data => {
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setDeals(jsonData);
      })
      .catch(error => console.error('Error fetching the deals data:', error));
  }, []);

  const lastUpdated = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="home">
      <NavBar /> {/* Adding the NavBar component */}
      <hr className="divider" />
      <div className="content">
        <div className="about-me">
          <h1>About Amir</h1>
          <div className="headshot-container">
            <img src={amirHeadshot} alt="Amir Luria" className="headshot" />
            <h2 className="headshot-title">Amir Luria Headshot</h2>
          </div>
          <p><strong>Amir Luria</strong> (אמיר לוריא)</p>
          <p><strong>VC:</strong> <a href="https://www.zanbato.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Zanbato Inc.</a></p>
          <p><strong>Email:</strong> <a href="mailto:bmaluria@gmail.com" style={{ color: 'blue', textDecoration: 'underline' }}> bmaluria@gmail.com</a></p>
          <p><strong>Languages:</strong> English, Hebrew</p>
          <p><strong>Loc:</strong> San Diego, California, USA</p>
          <p>
            I'm a venture secondaries trader with Zanbato Inc. in San Diego, CA. Previously, I worked on the Sales & Trading desk at <a href="https://www.gs.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Goldman Sachs</a> (2018-2020) and built a gaming startup with <a href="https://www.kalgrin.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Kal Grinberg</a>.
          </p>
        </div>
        <div className="closed-deals">
          <h1>Closed Deals</h1>
          <div className="deals-table-container">
            <table className="deals-table">
              <tbody>
                {deals.map((deal, index) => (
                  <tr key={index}>
                    <td className="company-name">
                      <IoTriangle color='black'/>
                      {logos[deal['Company']] && <img src={logos[deal['Company']]} alt={deal['Company']} className="company-logo" />}
                      <a href={deal.Link} target="_blank" rel="noopener noreferrer">
                        {deal['Company']}
                      </a>
                    </td>
                    <td>{deal.Segment}</td>
                    <td>{deal['Deal Size']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr className="bottom-divider" />
      <div className="footer">
        <p>Last updated: {lastUpdated}</p>
      </div>
    </div>
  );
}

export default Home;
